
import Share from '/utils/common/share'
const share = new Share();

export default {
    name: 'YfnShare',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        options: Object
    },
    data() {
        return {
            hasLogin: false,
            clear: '',
            link: [],
            step: [],
            user: {
                dstName: 't****o@163.com'
            },
            popup: {
                visible: false
            },
            customizeCompo: {
                visible: false,
                hasPopup: true,
                url: '',
                on: {}
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.hasLogin && this.getUserAjax()
    },
    methods: {
        handleLink(obj) {
            let queryArr = [
                `shareChannel=${obj.channel}`
            ];
            let queryStr = queryArr.join('&');
            let href = '';
            if(this.options.url.includes('?')) {
                href = `${this.options.url}&${queryStr}`
            } else {
                href = `${this.options.url}?${queryStr}`
            }
            if([1, 3, 6].includes(obj.id)) {
                this.setStorage()
            }
            if(obj.id ==5){
                this.reportOpen()
                this.options.visible = false
            }
            if(![4, 6].includes(obj.id)) { // 三方分享
                share.handle({
                    href: href,
                    type: obj.channel,
                    title: this.options.title,
                    description: this.options.description,
                    shareImg: this.options.img
                })
            }
            if(obj.id == 4) {
                this.setCopy(href)
                this.$Toast(`${this.$translate('Copied')}`)
            }
            if(obj.id == 6) {
                this.clear && clearTimeout(this.clear)
                this.clear = setTimeout(() => {
                    this.communityShareApi()
                }, 200)
            }
            this.options.visible = false
            this.$emit('emit-handle', obj)
        },
        // 复制
        setCopy(val) {
            this.$fnApi.copy.initial(val)
        },
        // 举报
        reportOpen() {
            const origin = location.origin;
            this.customizeCompo.visible = true
            this.customizeCompo.url = `${origin}/custonIndex/report?productCode=${this.options?.productInfo?.productCode}`
            this.customizeCompo.on = {
                jumpBack: () => {
                    this.customizeCompo.visible = false
                    this.customizeCompo.url = ''
                }
            }
        },
        // yfn社区分享
        communityShareApi() {
            this.$api.mixins.communityShare({
                data: JSON.stringify(this.options.community)
            }).then(response => {
                this.$Toast(`${this.$translate('Successful')}`)
            })
        },
        // 获取用户信息
        getUserAjax() {
            let userId = this.$storage.get('userId');
            this.$api.user.getUseInfo({
                id: userId
            }).then(response => {
                const res = response.result || {};
                this.user = res || {}
                let emailArr = (this.user.email || '').split('@');
                this.user.dstName = emailArr[0] ? `${this.dst(emailArr[0])}@${emailArr[1]}` : 't****o@163.com';
            })
        },
        // 分享信息缓存
        setStorage() {
            if(this.options.shareInfo) {
                this.$storage.set('review/commentShareInfo', window.btoa(JSON.stringify(this.options.shareInfo)))
            }
        },
        // 脱敏
        dst(name) {
            if (name && name != undefined && name != '') {
                let name2 = '';
                if (name.length == 2) {
                    name = name.substring(0, 1) + '****'
                }
                if (name.length > 2) {
                    name = name.substring(0, 1) + '****' + name.charAt(name.length - 1)
                }
                return name
            } else {
                return name
            }
        },
        // 初始化
        initialSet() {
            this.hasLogin = !!this.$storage.get('token');
            let hasCommunity = false;
            let hasReport = false;
            const keyArr = ['review-personal', 'review-success', 'review-detail'];
            const reportArr = ['/item'];
            const seoName =['Product-Seo','Product-Old']
            keyArr.some(p => {
                if(location.href.includes(p)) {
                    hasCommunity = true
                    return true
                }
            })
            reportArr.some(p => {
                if(location.href.includes(p)) {
                    hasReport = true
                    return true
                }
            })
            seoName.some(p=>{
                if(this.$route.name.includes(p)) {
                    hasReport = true
                    return true
                }
            })
            this.link = [{
                type: 'fb',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1692839244916.png',
                text: 'Facebook',
                channel: 'Facebook',
                id: 1,
            },
            // {
            //     type: 'pr',
            //     url: '/assets/icon/icon-pr.png',
            //     text: 'Pinterest'
            // },
            {
                type: 'tw',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1711696051658.png',
                text: 'Twitter',
                channel: 'Twitter',
                id: 3,
            }, {
                type: 'community',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1693201575534.png',
                text: 'Community',
                channel: 'YfnCommunity',
                id: 6,
                hasVisible: hasCommunity
            }, {
                type: 'copy',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1692839254742.png',
                text: `${this.$translate('Copy Link')}`,
                channel: 'CopyLink',
                id: 4,
            }, {
                type: 'Report',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1694845751383.png',
                text: `${this.$translate('Report')}`,
                channel: 'Report',
                id: 5,
                hasVisible: hasReport
            }]
            this.step = [{
                desc: 'Friends',
                text: 'Click Link',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996789094.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996855366.png',
            }, {
                text: 'Add to Bag',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996791889.png'
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996855366.png',
            }, {
                text: 'Purchase',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996793676.png',
            }, {
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996855366.png',
            }, {
                desc: 'You',
                text: 'Get Reward',
                url: 'https://cdn2.selleroa.com/yfn-upload/review/1691996796118.png'
            }]
        }
    },
}
